import React from "react";
import PropTypes from "prop-types";
import FeedCardHeader from "./FeedCardHeader";
import { Card } from "react-bootstrap";
import FeedCardContent from "./FeedCardContent";
import FeedCardFooter from "./FeedCardFooter";
import { Link } from "react-router-dom";

const FeedCard = ({ userData, links, ...rest }) => {
  const {
    username,
    created_at,
    tweet_text,
    like_count,
    retweet_count,
    reply_count,
    party,
    tweet_lang,
    link,
  } = userData;

  return (
    <Card {...rest}>
      {link && (
        <a href={link} style={{ textDecoration: "none", color: "inherit" }}>
          <FeedCardHeader
            username={username}
            date={created_at}
            green={party}
            lang={tweet_lang}
          />
          <FeedCardContent text={tweet_text} url={link} />
        </a>
      )}
      <FeedCardFooter
        likeCount={like_count}
        retweetCount={retweet_count}
        replyCount={reply_count}
      />
    </Card>
  );
};

FeedCard.Header = FeedCardHeader;

FeedCard.propTypes = {
  feed: PropTypes.object,
};

export default FeedCard;
