import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";
import Flex from "components/common/Flex";
import Avatar from "components/common/Avatar";
// import bg1 from "../../../../assets/img/team/5-thumb.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import SoftBadge from "components/common/SoftBadge";
import BJP from '../../assets/party/BJP.png'
const FeedCardHeader = ({
  status,
  avatarSrc,
  share,
  username,
  date,
  green,
  lang,
  chosenLink,
}) => {
  return (
    <Card.Header className="bg-light">
      <Row className="justify-content-between">
        <Col>
          <Flex>
            <Link className="d-flex">
              <Avatar size="2xl" src={BJP} className={status} />
            </Link>
            <div className="flex-1 align-self-center ms-2">
              <p className="mb-1 lh-1">
                <Link className="fw-semi-bold">{username}</Link>
                <SoftBadge bg="info" className="ms-2">
                  {green}
                </SoftBadge>
                {!!share && (
                  <span className="ms-1">
                    shared a <a href="#!">Tweet</a>
                  </span>
                )}
              </p>
              <span
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <p className="mb-0 fs--1">{date} </p>
                {/* Language:  */}
                <SoftBadge bg="info" className="ms-2">
                  {lang}
                </SoftBadge>
              </span>
            </div>
          </Flex>
        </Col>
        {/* <Col xs="auto">
          <Dropdown className="font-sans-serif btn-reveal-trigger">
            <Dropdown.Toggle
              variant="link"
              size="sm"
              className="px-1 text-600 btn-reveal"
            >
              <FontAwesomeIcon icon="share" />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Dropdown.Item onClick={() => navigator.clipboard.writeText(chosenLink)}>Copy Link</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col> */}
      </Row>
    </Card.Header>
  );
};

FeedCardHeader.propTypes = {
  status: PropTypes.string,
  avatarSrc: PropTypes.string,
  time: PropTypes.string,
  name: PropTypes.string,
  share: PropTypes.string,
  location: PropTypes.string,
  privacy: PropTypes.string,
};

export default FeedCardHeader;
const getBadgeColor = (partyName) => {
  switch (partyName) {
    case "BJP":
      return "success"; // BJP color is success
    case "INC":
      return "danger"; // INC color is danger
    default:
      return "warning"; // Default color for other parties
  }
};
