import React, { useState, useEffect } from "react";
import { Col, Row, Card, Pagination } from "react-bootstrap";
import FeedProvider from "./FeedProvider";
import FeedContent from "./FeedContent";
import { Dropdown } from "primereact/dropdown";
import WidgetSectionTitle from "widgets/WidgetSectionTitle";
import axios from "axios";
import FalconComponentCard from "components/common/FalconComponentCard";

const Feed = () => {
  const [selectedParty, setSelectedParty] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [userdata, setUserData] = useState([]);
  const [date, setDate] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [tweetLinks, setTweetLinks] = useState([]);

  console.log(selectedCategory, "--------catgcatgcatgcatg=-----");

  const partys = [
    { name: "All Tweets" },
    { name: "BJP" },
    { name: "INC" },
    { name: "TDP" },
    { name: "DMK" },
    { name: "IND" },
    { name: "AAP" },
    { name: "Samajwadi Party" },
  ];
  const time = [
    { name: "This Week", days: 7 },
    { name: "This Month", days: 30 },
    { name: "Last Month", days: 30 },
    { name: "Last Quarter", days: 90 },
  ];
  const status = [
    { name: "All" },
    { name: "Positive" },
    { name: "Negative" },
    { name: "Neutral" },
  ];
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const fetchTweets = async () => {
      try {
        const response = await axios.get(
          "https://faktual.ai/api/tweets/tweets"
        );
        let filteredData = JSON.parse(response.data);

        if (selectedParty && selectedParty.name !== "All Tweets") {
          filteredData = filteredData.filter(
            (tweet) => tweet.party === selectedParty.name
          );
        }
        if (selectedCategory && selectedCategory.name !== "All") {
          console.log(selectedCategory.name, "test");
          filteredData = filteredData.filter(
            (tweets) => tweets.results === selectedCategory.name
          );
        }

        // if (date) {
        //   const currentDate = new Date();
        //   const startDate = new Date(
        //     currentDate.getTime() - date.days * 24 * 60 * 60 * 1000
        //   );

        //   filteredData = filteredData.filter((tweet) => {
        //     const tweetDate = new Date(tweet.date);
        //     return tweetDate >= startDate && tweetDate <= currentDate;
        //   });
        // }

        setUserData(filteredData.reverse());

        // Extract links from tweets and store them
        const links = filteredData
          .map((tweet) =>
            tweet.tweet_text.match(/(https?|ftp):\/\/[^\s/$.?#].[^\s]*/g)
          )
          .flat()
          .filter(Boolean);
        setTweetLinks(links);
      } catch (error) {
        console.error("Error fetching tweets:", error);
      }
    };

    fetchTweets();
  }, [selectedCategory, selectedParty, date]);

  const totalPages = Math.ceil(userdata.length / itemsPerPage);
  const indexOfLastTweet = currentPage * itemsPerPage;
  const indexOfFirstTweet = indexOfLastTweet - itemsPerPage;
  const currentTweets = userdata.slice(indexOfFirstTweet, indexOfLastTweet);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const paginationItems = [];
  const pagesToShow = 5;

  if (totalPages <= pagesToShow) {
    for (let number = 1; number <= totalPages; number++) {
      paginationItems.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => paginate(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
  } else {
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    if (startPage > 1) {
      paginationItems.push(
        <Pagination.Ellipsis key="ellipsis-start" onClick={() => paginate(1)} />
      );
    }

    for (let number = startPage; number <= endPage; number++) {
      paginationItems.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => paginate(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      paginationItems.push(
        <Pagination.Ellipsis
          key="ellipsis-end"
          onClick={() => paginate(totalPages)}
        />
      );
    }
  }

  return (
    <FeedProvider>
      <WidgetSectionTitle
        icon="layer-group"
        title="Total Tweets"
        subtitle=""
        transform="shrink-2"
        className="mb-4 mt-0"
      />
      <Card.Body
        className="pb-0 mb-2 "
        style={{ width: width <= 991 ? "100%" : "91.33%" }}
      >
        <Card className="h-100">
          <div className="d-sm-flex p-1">
            <h4 className="d-sm-none text-primary m-4 mt-2 mb-2 d-flex align-items-center">
              Filters
            </h4>
            <Row className="m-3 justify-content-end text-center w-100 gap-2">
              <Col md={4}>
                <Dropdown
                  value={selectedParty}
                  onChange={(e) => setSelectedParty(e.value)}
                  options={partys}
                  optionLabel="name"
                  placeholder="Select a National Party"
                  className="w-full md:w-14rem"
                />
              </Col>
              <Col md={4}>
                <Dropdown
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.value)}
                  options={status}
                  optionLabel="name"
                  placeholder="Select a Category"
                  className="w-full md:w-14rem"
                />
              </Col>
              {/* <Col md={3}>
                <Dropdown
                  value={date}
                  onChange={(e) => setDate(e.value)}
                  options={time}
                  optionLabel="name"
                  placeholder="Select a Time & Duration"
                  className="w-full md:w-14rem"
                />
              </Col> */}
            </Row>
          </div>
        </Card>
      </Card.Body>
      <Row className="g-0 g-lg-3">
        <Col lg={11}>
          <FeedContent userData={currentTweets} link={tweetLinks} />
        </Col>
        <Col lg={4}>{/* <AddToFeed /> */}</Col>
      </Row>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Pagination>
          <Pagination.First onClick={() => paginate(1)} />
          <Pagination.Prev
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {paginationItems}
          <Pagination.Next
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last onClick={() => paginate(totalPages)} />
        </Pagination>
      </div>

      {/* <FalconComponentCard>
        <FalconComponentCard.Body code={moreOptionsCode} language="jsx" />
      </FalconComponentCard> */}
    </FeedProvider>
  );
};

export default Feed;
