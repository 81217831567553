import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import useBulkSelect from "hooks/useBulkSelect";
import usePagination from "hooks/usePagination";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Pagination,
  CloseButton,
} from "react-bootstrap";
import AllTicketsHeader from "./AllTicketsHeader";
import { totalSales } from "data/dashboard/default";
import TotalTickets from "components/dashboards/default/TotalTickets";
import axios from "axios";
import CampaignChart from "components/dashboards/topcx-analytics/campaign-perfomance/CampaignChart";
import { campaignChart } from "data/dashboard/analytics";
import { getColor, rgbaColor } from "helpers/utils";
import SoftBadge from "components/common/SoftBadge";

import { LineChart } from "echarts/charts";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";

import { CanvasRenderer } from "echarts/renderers";
import AdSenseAd from "components/AdSense/AdSenseAd";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
]);

const tooltipFormatter = (params) => {
  return `
<div>
    <h6 class="fs--1 text-700 mb-0">
    <span class="dot me-1 d-inline-block" style="background-color:${
      params[0].borderColor ? params[0].borderColor : params[0].color
    }"></span>
      ${params[0].name} : ${params[0].value}
    </h6>
</div>
`;
};

const CardView = () => {
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [userData, setUserData] = useState([]);
  const handleShow = () => setShow(true);
  const [selectedUsername, setSelectedUsername] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [graphDates, setGraphDates] = useState([]);

  const { selectedItems, isSelectedItem, toggleSelectedItem } = useBulkSelect(
    []
  );
  const [primaryTickets, setPrimaryTickets] = useState([]);
  const [originalTickets, setOriginalTickets] = useState([]);
  const {
    paginationState: {
      data: paginatedTicket,
      currentPage,
      canNextPage,
      canPreviousPage,
    },
    nextPage,
    prevPage,
    goToPage,
  } = usePagination(primaryTickets, 10);
  // const convertToMillion = (num) => {
  //   return (num / 1000000).toFixed(2) + "M";
  // };
  const convertToMillion = (num) => {
    if (Math.abs(num) >= 1e6) {
      return (num / 1e6).toFixed(2) + "M";
    } else if (Math.abs(num) >= 1e3) {
      return (num / 1e3).toFixed(2) + "K";
    } else {
      return num.toString();
    }
  };
  const convertToK = (num) => {
    return (num / 1000).toFixed(2) + "K";
  };
  console.log(selectedUsername, "-----------SelectUSername----------");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://faktual.ai/api/tweets/top_leader_follower"
        );
        // const setPrimaryTickets = response.data;
        setPrimaryTickets(response.data);
        setOriginalTickets(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleTicketsSearch = (text) => {
    const filteredTickets = originalTickets.filter((ticket) =>
      ticket.Username.toLowerCase().includes((text || "").toLowerCase())
    );
    setPrimaryTickets(filteredTickets);
  };

  const handleUsernameClick = (username) => {
    setSelectedUsername(username);
    setModalShow(true);
  };
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;

  const totalPages = Math.ceil(primaryTickets.length / itemsPerPage);

  // ........................................Graph API

  useEffect(() => {
    const fetchGraphData = async () => {
      try {
        const postData = {
          username: selectedUsername,
        };
        const response = await axios.post(
          `https://faktual.ai/api/tweets/graph_data?username=${selectedUsername}`,
          postData
        );
        const responseData = response.data;

        const formattedDates = responseData.DateTime.map((dateTime) => {
          return new Date(dateTime).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
          });
        });

        setGraphDates(formattedDates);
        setGraphData(responseData.FollowCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchGraphData();
  }, [modalShow]);

  const getOption = () => {
    const minValue = Math.min(...graphData);
    const maxValue = Math.max(...graphData);

    // Calculate padding percentages for the y-axis scale
    const paddingPercentage = 0.1; // Adjust the padding as needed
    const minPadding = (maxValue - minValue) * paddingPercentage;
    const maxPadding = (maxValue - minValue) * paddingPercentage;

    // Set the minimum and maximum values for the y-axis with padding
    const yAxisMin = Math.max(0, minValue - minPadding);
    const yAxisMax = maxValue + maxPadding;
    return {
      tooltip: {
        trigger: "axis",
        padding: [7, 10],
        backgroundColor: getColor("gray-100"),
        borderColor: getColor("gray-300"),
        textStyle: getColor("dark"),
        borderWidth: 1,
        formatter: tooltipFormatter,
        transitionDuration: 0,
        axisPointer: {
          type: "none",
        },
      },
      xAxis: {
        type: "category",
        data: graphDates.map((date) => date.substring(0, 20)),
        boundaryGap: false,
        axisLine: {
          lineStyle: {
            color: getColor("gray-300"),
          },
        },
        axisTick: { show: false },
        axisLabel: {
          color: getColor("gray-400"),
          formatter: (value) => value.substring(0, 6),
          margin: 15,
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: "value",
        min: yAxisMin,
        max: yAxisMax,
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: getColor("gray-200"),
          },
        },
        boundaryGap: false,
        axisLabel: {
          show: true,
          color: getColor("gray-400"),
          margin: 15,
          formatter: (value) => {
            if (value >= 1000000) {
              return `${value / 1000000}M`;
            } else if (value >= 1000) {
              return `${value / 1000}K`;
            } else {
              return value.toString();
            }
          },
        },
        axisTick: { show: false },
        axisLine: { show: false },
      },
      series: [
        {
          type: "line",
          data: graphData, // Use graphData for series data
          itemStyle: {
            color: getColor("white"),
            borderColor: getColor("primary"),
            borderWidth: 2,
          },
          lineStyle: {
            color: getColor("primary"),
          },
          showSymbol: false,
          symbol: "circle",
          symbolSize: 10,
          smooth: false, // Use smooth curve for the line
          emphasis: {
            scale: true,
          },
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: rgbaColor(getColor("primary"), 0.5),
                },
                {
                  offset: 1,
                  color: rgbaColor(getColor("primary"), 0),
                },
              ],
            },
          },
        },
      ],
      grid: { right: "3%", left: "10%", bottom: "10%", top: "5%" },
    };
  };
  return (
    <Row className="gx-3">
      <Col xxl={10} xl={9}>
        <Card>
          <Card.Header className="border-bottom border-200 px-0">
            <AllTicketsHeader
              layout="card-view"
              handleShow={handleShow}
              // selectedItems={selectedItems}
              handleTicketsSearch={handleTicketsSearch}
            />
          </Card.Header>
          <Card.Body className="bg-light">
            <div className="d-flex flex-column gap-3">
              {paginatedTicket.map((user, index) => (
                <div
                  key={startIndex + index}
                  className="bg-white dark__bg-1100 d-md-flex d-xl-inline-block d-xxl-flex align-items-center p-x1 rounded-3 shadow-sm card-view-height w-100"
                >
                  <Row className="g-0 flex-between-center w-100">
                    <Col lg={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="d-flex align-items-start align-items-sm-center cursor-pointer"
                          onClick={() => handleUsernameClick(user.Username)}
                        >
                          <p
                            className="fw-semi-bold mb-3 mb-sm-2 me-2"
                            style={{
                              width: "2rem",
                              height: "2rem",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {startIndex + index + 1}
                          </p>
                          <img src={user.PFP_URL} />
                          <div className="ms-2 ms-sm-3">
                            <p className="fw-semi-bold mb-1 mb-sm-2">
                              {" "}
                              {user.Username}
                            </p>
                            <Row className="align-items-center gx-0 gy-2">
                              <Col xs="auto" className="me-2">
                                <h6 className="mb-1">
                                  <FontAwesomeIcon
                                    icon="user"
                                    transform="shrink-3 up-1"
                                  />
                                  <span>
                                    {"  "}Followers Count :{" "}
                                    <SoftBadge bg="success" className="ms-2">
                                      {convertToMillion(user.FollowCount)}
                                    </SoftBadge>
                                  </span>
                                </h6>
                                <h6>
                                  <FontAwesomeIcon
                                    icon="user"
                                    transform="shrink-3 up-1"
                                  />
                                  <span>
                                    {"  "}Tweets Count :
                                    <SoftBadge bg="success" className="ms-2">
                                      {convertToK(user.TweetCount)}
                                    </SoftBadge>
                                  </span>
                                </h6>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <Button
                          variant="outline-success"
                          className="me-2 mb-1 d-none d-lg-block "
                          onClick={() => handleUsernameClick(user.Username)}
                        >
                          Followers Graph
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
            <Modal
              show={modalShow}
              onHide={() => setModalShow(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  <span className="text-primary"> {selectedUsername} </span>{" "}
                  Followers Chart
                </Modal.Title>
                <CloseButton
                  className="btn btn-circle btn-sm transition-base p-0"
                  onClick={() => setModalShow(false)}
                />
              </Modal.Header>
              <Modal.Body>
                <Row className="g-3 mb-3">
                  <Col lg={12}>
                    <ReactEChartsCore
                      echarts={echarts}
                      option={getOption()}
                      style={{ height: "18.75rem" }}
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => setModalShow(false)}>Close</Button>
              </Modal.Footer>
            </Modal>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Pagination>
                <Pagination.First onClick={() => goToPage(1)} />
                <Pagination.Prev
                  onClick={prevPage}
                  disabled={!canPreviousPage}
                />
                {[...Array(totalPages).keys()].map((page) => (
                  <Pagination.Item
                    key={page}
                    active={currentPage === page + 1}
                    onClick={() => goToPage(page + 1)}
                  >
                    {page + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                <Pagination.Last onClick={() => goToPage(totalPages)} />
              </Pagination>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col xxl={2} xl={3}>
        {/* <div>
          <script
            async
            src="
https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1496279226276009"
            crossorigin="anonymous"
          ></script>
          <ins
            class="adsbygoogle"
            // style="display:inline-block;width:728px;height:90px"
            style={{
              display: "block",
              width: "100% ",
              height: "600px",
            }}
            data-ad-client="ca-pub-1496279226276009"
            data-ad-slot="1234567890"
          ></ins>
          <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
        </div> */}
      </Col>
    </Row>
  );
};

export default CardView;
