export const dashboardRoutes = {
  label: "Dashboard",
  labelDisable: true,
  children: [
    {
      name: "Home",
      to: "/",
      active: true,
      icon: "chart-pie",
    },
    {
      name: "Tweets",
      icon: "chart-line",
      to: "/dashboard",
      exact: true,
      active: true,
    },
    {
      name: "Top 100 Political Leaders",
      icon: "user",
      to: "/twitter-followers",
      active: true,
    },
  ],
};

export default [dashboardRoutes];
