import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Background from "components/common/Background";
import Flex from "components/common/Flex";
import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
// import corner1 from 'assets/img/icons/spot-illustrations/corner-2.png';
import PropTypes from "prop-types";

const Greetings6 = ({ data, count }) => {
  return (
    <Card className="h-100">
      <Background className="rounded-soft bg-card" />
      <Card.Header className="z-index-1">
        {/* <h5 className="text-primary"> */}
        <Button variant="falcon-primary" className="me-2 mb-1 w-100 h-100 p-2">
          Negative posts about Congress and its Leaders
        </Button>
        {/* </h5> */}
        {/* <h6 className="text-600">Here are some quick Tweets</h6> */}
      </Card.Header>
      <h4
        className="text-primary"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Button variant="outline-success" className="me-2 mb-1">
          Tweet's Count : {count}
        </Button>
      </h4>
      <hr
        className="my-1"
        style={{
          border: "none",
          height: "1px",
          background:
            "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))",
        }}
      />
      <Card.Body className="z-index-1">
        <Row className="g-2 h-100 align-items-end">
        {data?.negative_inc_comments?.map((comment, index) => (
            <Col
              sm={12}
              md={12}
              className="d-flex mb-2"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                maxWidth: "80%",
                whiteSpace: "nowrap",
              }}
              key={index}
            >
              <Flex className="position-relative">
                <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                  {/* You can use any icon here */}
                  <FontAwesomeIcon icon="user" className={`text-primary`} />
                </div>
                <div className="flex-1">
                  <Link to={`https://twitter.com/${comment.username}`} className="stretched-link text-700">
                    <h6 className="mb-0">{comment.tweet_text}</h6>
                  </Link>
                  <p className="mb-0 fs--1 text-500">@{comment.username}</p>
                </div>
              </Flex>
            </Col>
          ))}
          <hr
            className="my-2"
            style={{
              border: "none",
              height: "1px",
              background:
                "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))",
            }}
          />
          <Link to="/dashboard/Positive_posts_about_Congress" style={{ textDecoration: "none" }}>
            <Col
              xs={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button variant="outline-success" className="me-2 mb-1 w-50">
                Explore More
              </Button>
            </Col>
          </Link>
        </Row>
      </Card.Body>
    </Card>
  );
};

Greetings6.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ),
};

export default Greetings6;
