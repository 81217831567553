import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotificationDropdown from "components/navbar/top/NotificationDropdown";
import AppContext from "context/Context";
import { Nav, OverlayTrigger, Tooltip } from "react-bootstrap";

const TopNavRightSideNavItem = () => {
  const {
    config: { isDark, isRTL },
    setConfig,
  } = useContext(AppContext);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "hi",
        autoDisplay: true,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <Nav.Item as={"li"}>
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => setConfig("isDark", !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? "bottom" : "left"}
            overlay={
              <Tooltip style={{ position: "fixed" }} id="ThemeColor">
                {isDark ? "Switch to light theme" : "Switch to dark theme"}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? "sun" : "moon"}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>
      <div
        id="google_translate_element"
        style={{
          border: "1px solid #ccc",
          padding: "10px",
          borderRadius: "5px",
        }}
      ></div>
      {/* <NotificationDropdown /> */}

      {/* <ProfileDropdown /> */}
    </Nav>
  );
};

export default TopNavRightSideNavItem;
