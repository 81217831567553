import React from "react";
import { Navigate, Route, Routes, BrowserRouter } from "react-router-dom";

import MainLayout from "../layouts/MainLayout";
import ErrorLayout from "../layouts/ErrorLayout";

import Error404 from "components/errors/Error404";
import Error500 from "components/errors/Error500";

import Widgets from "widgets/Widgets";
import CardView from "components/app/support-desk/tickets-layout/CardView";
import Feed from "components/app/social/feed/Feed";
import Feed2 from "widgets/feed/Feed";
import Feed3 from "widgets/AllTweetFilterCards/feed/Feed";
import Feed4 from "widgets/AllTweetFilterCards/SecondCard/feed/Feed";
import Feed5 from "widgets/AllTweetFilterCards/SecondCard/ThirdCard/feed/Feed";
// const googleTranslateElementInit = () => {
//   new window.google.translate.TranslateElement(
//     {
//       pageLanguage: "en",
//       autoDisplay: false
//     },
//     "google_translate_element"
//   );
// };
// useEffect(() => {
//   var addScript = document.createElement("script");
//   addScript.setAttribute(
//     "src",
//     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
//   );
//   document.body.appendChild(addScript);
//   window.googleTranslateElementInit = googleTranslateElementInit;
// }, []);
const FalconRoutes = () => {
  return (
    // <BrowserRouter>
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>
      {/* my routes */}
      <Route element={<MainLayout />}>
        <Route path="/" element={<Widgets />} />
        <Route path="/twitter-followers" element={<CardView />} />
        <Route path="/dashboard" element={<Feed />} />
        <Route path="/dashboard/Positive_posts_about_BJP" element={<Feed2 />} />
        <Route path="/dashboard/Negative_posts_about_BJP" element={<Feed3 />} />
        <Route path="/dashboard/Positive_posts_about_Congress" element={<Feed4 />} />
        <Route path="/dashboard/Positive_posts_about_Congress" element={<Feed5 />} />
      </Route>
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
    // </BrowserRouter>
  );
};

export default FalconRoutes;
