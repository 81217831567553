import { v4 as uuid } from 'uuid';
import eventImg1 from 'assets/img/generic/13.jpg';
import team15 from 'assets/img/team/15.jpg';
import user1 from 'assets/img/team/17.jpg';
import user4 from 'assets/img/team/6.jpg';
import user5 from 'assets/img/team/12.jpg';
import user6 from 'assets/img/team/11.jpg';
import user13 from 'assets/img/team/13.jpg';
import user10 from 'assets/img/team/10.jpg';
import user8 from 'assets/img/team/8.jpg';
import generic11 from 'assets/img/generic/11.jpg';
import generic12 from 'assets/img/generic/12.jpg';
import av4 from 'assets/img/team/4.jpg';
import img1 from 'assets/img/generic/4.jpg';
import img2 from 'assets/img/generic/5.jpg';
import img3 from 'assets/img/gallery/4.jpg';
import img4 from 'assets/img/gallery/5.jpg';
import img5 from 'assets/img/gallery/3.jpg';

export default [
  {
    id: uuid(),
    user: {
      name: 'Margot Robbie',
      avatarSrc: user5,
      time: '14Feb',
      location: 'London',
      privacy: 'friends',
      share: 'photo'
    },
    content: {
      status:
        'Greetings to Mizoram CM Pu Lalduhoma on his birthday. May Almighty bless him with a long and healthy life in service of the people.'
    },
    details: {
      countLCS: {
        like: 4,
        comment: 20
      },
      reactions: {
        like: false,
        comment: true,
        share: false
      },
      comments: [
        {
          id: uuid(),
          avatarSrc: av4,
          name: 'Rowan Atkinson',
          content:
            ' Jessalyn Sarah Gilsig is a Canadian-American actress known for her roles in television series, e.g., as Lauren Davis in Boston Public, Gina Russo in Nip/Tuck, Terri Schuester in Glee, and as Siggy Haraldson on the History Channel series Vikings. 🏆',
          postTime: '2min'
        }
      ]
    }
  },
  {
    id: uuid(),
    user: {
      name: 'Margot Robbie',
      avatarSrc: user6,
      time: '14Feb',
      location: 'London',
      privacy: 'friends',
      share: 'photo'
    },
    content: {
      status:
        "Don't overpay on Amazon ever again! This Amazon shopping hack is like Prime Day on steroids."
    },
    details: {
      countLCS: {
        like: 4,
        comment: 20
      },
      reactions: {
        like: false,
        comment: true,
        share: false
      },
      comments: [
        {
          id: uuid(),
          avatarSrc: av4,
          name: 'Rowan Atkinson',
          content:
            ' Jessalyn Sarah Gilsig is a Canadian-American actress known for her roles in television series, e.g., as Lauren Davis in Boston Public, Gina Russo in Nip/Tuck, Terri Schuester in Glee, and as Siggy Haraldson on the History Channel series Vikings. 🏆',
          postTime: '2min'
        }
      ]
    }
  },
  {
    id: uuid(),
    user: {
      name: 'Margot Robbie',
      avatarSrc: user6,
      time: '14Feb',
      location: 'London',
      privacy: 'friends',
      share: 'photo'
    },
    content: {
      status:
        'देशभर के अपने किसान भाई-बहनों के कल्याण से जुड़े हर संकल्प को पूरा करने के लिए हमारी सरकार प्रतिबद्ध है। इसी कड़ी में गन्ना खरीद की कीमत में ऐतिहासिक बढ़ोतरी को मंजूरी दी गई है। इस कदम से हमारे करोड़ों गन्ना उत्पादक किसानों को लाभ होगा।'
    },
    details: {
      countLCS: {
        like: 4,
        comment: 20
      },
      reactions: {
        like: false,
        comment: true,
        share: false
      },
      comments: [
        {
          id: uuid(),
          avatarSrc: av4,
          name: 'Rowan Atkinson',
          content:
            ' Jessalyn Sarah Gilsig is a Canadian-American actress known for her roles in television series, e.g., as Lauren Davis in Boston Public, Gina Russo in Nip/Tuck, Terri Schuester in Glee, and as Siggy Haraldson on the History Channel series Vikings. 🏆',
          postTime: '2min'
        }
      ]
    }
  },
  {
    id: uuid(),
    user: {
      name: 'Rowan Atkinson',
      avatarSrc: user4,
      time: '11 hrs',
      location: 'Consett, UK',
      privacy: 'friends',
      share: 'album',
      status: 'status-online'
    },
    content: {
      status:
        'Rowan Sebastian Atkinson CBE is an English actor, comedian and screenwriter best known for his work on the sitcoms Blackadder and Mr. Bean.',
      gallery: [img1, img2, img3, img4, img5]
    },
    details: {
      countLCS: {
        like: 345
      },
      reactions: {
        like: true,
        comment: true,
        share: false
      },
      comments: [
        {
          id: uuid(),
          avatarSrc: user4,
          name: 'Rowan Atkinson',
          content: `She starred as Jane Porter in The <a href="#!">@Legend of Tarzan (2016)</a>, Tanya Vanderpoel in for which nominated for a Teen Choice Award, and many other awards.`,
          postTime: '23hrs'
        },
        {
          id: uuid(),
          avatarSrc: user13,
          name: 'Jessalyn Gilsig',
          content:
            ' Jessalyn Sarah Gilsig is a Canadian-American actress known for her roles in television series, e.g., as Lauren Davis in Boston Public, Gina Russo in Nip/Tuck, Terri Schuester in Glee, and as Siggy Haraldson on the History Channel series Vikings. 🏆',
          postTime: '5hrs'
        }
      ],
      otherComments: '2 of 34'
    }
  },
  {
    id: uuid(),
    user: {
      name: 'Margot Robbie',
      avatarSrc: team15,
      time: '14Feb',
      location: 'London',
      privacy: 'friends',
      share: 'photo'
    },
    content: {
      status: 'Margot Elise Robbie was born on July 2'
    },
    details: {
      countLCS: {
        like: 4,
        comment: 20
      },
      reactions: {
        like: false,
        comment: true,
        share: false
      },
      comments: [
        {
          id: uuid(),
          avatarSrc: av4,
          name: 'Rowan Atkinson',
          content:
            ' Jessalyn Sarah Gilsig is a Canadian-American actress known for her roles in television series, e.g., as Lauren Davis in Boston Public, Gina Russo in Nip/Tuck, Terri Schuester in Glee, and as Siggy Haraldson on the History Channel series Vikings. 🏆',
          postTime: '2min'
        }
      ]
    }
  },
  {
    id: uuid(),
    user: {
      name: 'Leonardo DiCaprio ',
      avatarSrc: user10,
      time: '13 jan',
      location: 'LA, US',
      privacy: 'public',
      share: 'photo'
    },
    content: {
      imgSrc: generic11
    },
    details: {
      countLCS: {
        like: 3
      },
      reactions: {
        like: false,
        comment: false,
        share: false
      }
    }
  },
  {
    id: uuid(),
    content: {
      feedEvent: {
        title: "Free new year's eve midnight harbor",
        author: 'Boston harbor now',
        calender: {
          day: '31',
          month: 'Dec'
        },
        regFee: '$49.99 – $89.99',
        eventImg: eventImg1
      }
    },
    details: {
      countLCS: {
        like: 130,
        share: 65
      },
      reactions: {
        like: false,
        comment: false,
        share: false
      },
      comments: [
        {
          id: uuid(),
          avatarSrc: generic11,
          name: 'Rowan Atkinson',
          content: `He starred as Jane Porter in The <a href="#!">@Legend of Tarzan (2016)</a>, Tanya Vanderpoel in for which nominated for a Teen Choice Award, and many other awards.`,
          postTime: '1hr'
        }
      ]
    }
  },
  {
    id: uuid(),
    user: {
      name: 'Johnny Depp',
      avatarSrc: user8,
      time: 'Just Now',
      location: 'Paris',
      privacy: 'friends',
      share: 'video',
      status: 'status-online'
    },
    content: {
      status:
        'See the sport of surfing as it’s never been captured before in John Florence and Blake Vincent Kueny’s second signature release, in association with the award-winning film studio, Brain Farm. The first surf film shot entirely in 4K, View From a Blue Moon. 🤩 🌎 🎬',
      video: {
        src: 'https://www.youtube.com/watch?v=bTqVqk7FSmY',
        type: 'youtube',
        videoId: 'bTqVqk7FSmY'
      }
    },
    details: {
      countLCS: {
        like: 0
      },
      reactions: {
        like: false,
        comment: false,
        share: false
      }
    }
  },
  {
    id: uuid(),
    user: {
      name: 'Emilia Clarke',
      avatarSrc: user1,
      time: '16 Dec',
      location: 'Bangladesh',
      privacy: 'public',
      status: 'status-online',
      share: 'url'
    },
    content: {
      url: {
        imgUrl: generic12,
        urlAddress: 'EN.WIKIPEDIA.ORG',
        title: "Mount Everest: Facts & Location of World's Highest Mountain",
        description:
          "The Himalayan range has many of the Earth's highest peaks, including the highest, Mount Everest..."
      }
    },
    details: {
      countLCS: {
        like: 324
      },
      reactions: {
        like: true,
        comment: false,
        share: false
      }
    }
  }
];
