import React from 'react';
import PropTypes from 'prop-types';

const FeedUrl = ({ imgUrl, hello, title, description }) => (
  <a className="text-decoration-none" href="#!">
    {/* {!!hello && <img className="img-fluid rounded" src={hello} alt="" />} */}
    {/* <small className="text-uppercase text-700">{hello}</small> */}
    {/* <h6 className="fs-0 mb-0">{hello}</h6> */}
    {!!hello && <p className="fs--1 mb-0 text-700">{hello}</p>}
  </a>
);
// console.log(hello,"--------address-------------")

FeedUrl.propTypes = {
  urlAddress: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imgUrl: PropTypes.string
};
export default FeedUrl;
