import React, { useState, useEffect } from "react";
import WidgetSectionTitle from "./WidgetSectionTitle";
import { Col, Row, Image, Figure } from "react-bootstrap";
import { greetingItems } from "data/dashboard/projectManagement";
import FalconComponentCard from "components/common/FalconComponentCard";
import axios from "axios";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { LineChart } from "echarts/charts";
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { getColor } from "helpers/utils";
import Greetings3 from "components/dashboards/project-management/Greetings3";
import Greetings4 from "components/dashboards/project-management/Greetings4";
import Greetings5 from "components/dashboards/project-management/Greetings5";
import Greetings6 from "components/dashboards/project-management/Greetings6";
import SoftBadge from "components/common/SoftBadge";
import "../main.css";
import Plot from "react-plotly.js";
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
]);

const exampleCode = `
<Figure>
  <Figure.Image
    alt="figure"
    src="https://faktual.ai/api/tweets/static/wordcloud.png"
  />
  <Figure.Caption className='text-end'>
   A caption for the above image.
  </Figure.Caption>
</Figure>
`;

const OthersWidgets = () => {
  const [data, setData] = useState([]);
  const [followersCount, setFollowersCount] = useState({});
  const [tweetCount, setTweetCount] = useState([]);
  const [xAxisData, setXAxisData] = useState([]);
  const [tweet, setTweet] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [mainData2, setMainData2] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://faktual.ai/api/tweets/leaders_data"
        );
        const formattedData = Object.entries(response.data).map(
          ([leaderName, leaderData]) => ({
            name: leaderName,
            FollowCount: leaderData.FollowCount,
            TweetCount: leaderData.TweetCount,
            DateTime: leaderData.DateTime.slice(-60),
          })
        );
        const followerCounts = formattedData.map(
          ({ FollowCount }) => FollowCount
        );

        const lastFollowersCounts = {};
        const lastTweetCounts = {};
        formattedData.forEach(({ name, FollowCount, TweetCount }) => {
          const lastFollowersCount = Math.max(...FollowCount);
          const lastTweetCount = Math.max(...TweetCount);
          lastFollowersCounts[name] = lastFollowersCount;
          lastTweetCounts[name] = lastTweetCount;
        });
        const formattedXAxisData = formattedData.map((dateString) => {
          const date = new Date(dateString);
          return `${date.getDate()}-${date.getMonth() + 1}`;
        });
        setMainData(followerCounts[0]);
        setMainData2(followerCounts[1]);
        setData(formattedData);
        setXAxisData(
          formattedXAxisData.length > 0 ? formattedData[0].DateTime : []
        );
        setFollowersCount(lastFollowersCounts);
        setTweetCount(lastTweetCounts);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://faktual.ai/api/tweets/top_least_tweet"
        );
        setTweet(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const leadersNames = data.map((leaderData) => leaderData.name);
  const daysAdjusted = Array.from({ length: 60 }, (_, i) => i + 2);

  const plotStyle = {
    width: "100%",
    height: "100%",
  };

  return (
    <>
      <WidgetSectionTitle
        icon="folder-plus"
        title="A word Cloud of most frequently occurring words in tweets of top 100 political leaders of India "
        subtitle=""
        transform="shrink-2"
        className="mb-4 mt-0"
      />
      {/* <Image src="https://faktual.ai/api/tweets/static/wordcloud.png" fluid /> */}
      <Figure
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="mb-4"
      >
        <Figure.Image
          alt="figure"
          src="https://faktual.ai/api/tweets/static/wordcloud.png"
          className="rounded .w-sm-100 w-lg-60"
        />
      </Figure>
      <Figure.Caption className="text-end"></Figure.Caption>
      <WidgetSectionTitle
        icon="folder-plus"
        title="Narendra Modi Vs Rahul Gandhi"
        subtitle=""
        transform="shrink-2"
        className="mb-4 mt-0"
      />
      <div style={{ marginBottom: "4rem" }}>
        <Col md={12} className="mb-2">
          <FalconComponentCard.Header title="Followers Count" light={false}>
            {leadersNames?.map((leaderName, index) => (
              <>
                <SoftBadge
                  key={index}
                  bg={index === 0 ? "primary" : "info"}
                  className="ms-2"
                >
                  {leaderName}:{" "}
                  {followersCount[leaderName] != null
                    ? (followersCount[leaderName] / 1000000).toFixed(2) + "M"
                    : "Loading..."}
                </SoftBadge>
                <SoftBadge
                  className="ms-2"
                  key={index}
                  bg={index === 0 ? "primary" : "info"}
                >
                  {leaderName} tweetCount:{" "}
                  {tweetCount[leaderName] != null
                    ? (tweetCount[leaderName] / 1000).toFixed(2) + "K"
                    : "Loading..."}{" "}
                </SoftBadge>
              </>
            ))}
          </FalconComponentCard.Header>
        </Col>

        <Plot
          data={[
            {
              x: xAxisData,
              y: mainData,
              type: "scatter",
              mode: "lines+markers",
              name: "Total Followers Narendra Modi",
              marker: { color: "blue" },
              yaxis: "y",
            },
          ]}
          layout={{
            margin: { t: 0, l: 50, r: 50, b: 0 },
            height: 350,
            legend: { orientation: "h", y: -0.1, x: 0.12 },
            template: "plotly_white",
            plot_bgcolor: "#0d1626",
            paper_bgcolor: "#0d1626",
            font: {
              color: "white",
            },
            hovermode: "closest",
            staticPlot: isMobile,
            yaxis: {
              range: [95600000, 97999000],
            },
            yaxis2: {
              title: "Daily Gain in Followers",
              side: "right",
              overlaying: "y",
            },
          }}
          config={{
            staticPlot: isMobile,
            displayModeBar: false,
          }}
          style={{ pointerEvents: "none" }}
        />
        <Plot
          data={[
            {
              x: xAxisData,
              y: mainData2,
              type: "scatter",
              mode: "lines+markers",
              name: "Total Followers Rahul Gandhi",
              marker: { color: "#139acf" },
              yaxis: "y",
            },
          ]}
          layout={{
            yaxis: { range: [25000000, 25900000] },
            margin: { t: 0, l: 50, r: 50, b: 40 },
            height: 300,
            legend: { orientation: "h", y: -0.2, x: 0.12 },
            template: "plotly_white",
            plot_bgcolor: "#0d1626",
            paper_bgcolor: "#0d1626",
            font: {
              color: "white",
            },
          }}
          config={{
            staticPlot: isMobile,
            displayModeBar: false,
          }}
          style={plotStyle}
        />
      </div>
      <WidgetSectionTitle
        icon="folder-plus"
        title="Twitter Cards"
        subtitle=""
        transform="shrink-2"
        className="mb-4 mt-6x"
      />
      <Row className="g-3 mb-3">
        <Col xl={6}>
          <Greetings3 data={tweet} count={tweet.positive_bjp_count} />
        </Col>
        <Col xl={6}>
          <Greetings4 data={tweet} count={tweet.negative_bjp_count} />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col xl={6}>
          <Greetings5 data={tweet} count={tweet.positive_inc_count} />
        </Col>
        <Col xl={6}>
          <Greetings6 data={tweet} count={tweet.negative_inc_count} />
        </Col>
      </Row>
    </>
  );
};

export default OthersWidgets;
