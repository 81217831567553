import React from "react";
import OthersWidgets from "./OthersWidgets";

const Widgets = () => {
  return (
    <>
      <OthersWidgets />
    </>
  );
};

export default Widgets;
