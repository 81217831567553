import React, { useState } from "react";
import PropTypes from "prop-types";
import FeedCardHeader from "./FeedCardHeader";
import { Card } from "react-bootstrap";
import FeedCardContent from "./FeedCardContent";
import FeedCardFooter from "./FeedCardFooter";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const FeedCard = ({ userData, links, ...rest }) => {
  const [chosenLink, setChosenLink] = useState("");
  const {
    username,
    pull_datetime,
    tweet_text,
    tweet_id,
    like_count,
    retweet_count,
    reply_count,
    party,
    tweet_lang,
    results,
  } = userData;
  console.log(userData, "------Total Data-----");
  const removeLinkFromText = (text) => {
    const linkRegex = /\s*(https?:\/\/\S+)\s*$/;
    const match = text.match(linkRegex);
    if (match) {
      const cleanedText = text.replace(linkRegex, "");
      const extractedLink = match[1];
      return { cleanedText, extractedLink };
    } else {
      return { cleanedText: text, extractedLink: "" };
    }
  };

  const { cleanedText, extractedLink } = removeLinkFromText(tweet_text);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(chosenLink)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
  };

  return (
    <Card {...rest}>
          <FeedCardHeader
            username={username}
            date={pull_datetime}
            green={party}
            lang={tweet_lang}
            catg={results}
          />
      {extractedLink && (
        <a
          href={extractedLink}
          target="_blank"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <FeedCardContent text={cleanedText} url={extractedLink} />
        </a>
      )}
      <FeedCardFooter
        likeCount={like_count}
        retweetCount={retweet_count}
        replyCount={reply_count}
      />
    </Card>
  );
};

FeedCard.Header = FeedCardHeader;

FeedCard.propTypes = {
  feed: PropTypes.object,
};

export default FeedCard;
