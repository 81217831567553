import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import FeedActionButtons from "./FeedActionButtons";

const FeedCardFooter = ({ likeCount, retweetCount, replyCount }) => {
  return (
    <Card.Footer className="bg-light pt-0">
      <FeedActionButtons
        likeCounts={likeCount}
        retweetCounts={retweetCount}
        replyCounts={replyCount}
      />
    </Card.Footer>
  );
};

FeedCardFooter.propTypes = {
  id: PropTypes.string.isRequired,
  countLCS: PropTypes.object.isRequired,
  reactions: PropTypes.object,
  comments: PropTypes.array,
  otherComments: PropTypes.string,
};

FeedCardFooter.defaultProps = {
  countLCS: { like: 0 },
  comments: [],
};

export default FeedCardFooter;
