import React, { useContext } from "react";
import FeedCard from "./FeedCard";
import classNames from "classnames";
import { Link } from "react-router-dom";

const FeedContent = ({ data, onCardClick }) => {
  if (!Array.isArray(data)) {
    console.error("userData is not an array:", data);
    return null; // Or you can return a placeholder or loading message
  }
  return (
    <>
      {data?.map((item, index) => (
        <FeedCard
          key={index}
          userData={item}
          className={classNames({
            "mb-3": index + 1 !== data.length,
            "mb-3 mb-lg-0": index + 1 === data.length,
          })}
          onClick={() => onCardClick(item)}
        />
      ))}
    </>
  );
};

export default FeedContent;