import React, { useContext } from "react";
import { FeedContext } from "context/Context";
import CreatePost from "./CreatePost";
import FeedCard from "./FeedCard";
import classNames from "classnames";
import { Link } from "react-router-dom";

const FeedContent = ({ userData, link }) => {
  return (
    <>
      {userData?.map((data, index) => (
        <FeedCard
          links={link}
          key={index}
          userData={data}
          className={classNames({
            "mb-3": index + 1 !== userData.length,
            "mb-3 mb-lg-0": index + 1 === userData.length,
          })}
        />
      ))}
    </>
  );
};

export default FeedContent;
