import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import Flex from "components/common/Flex";
import Avatar from "components/common/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import SoftBadge from "components/common/SoftBadge";
import {
  faCheckCircle,
  faTimesCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

import BJP from "../../../../assets/party/BJP.png";
import AAP from "../../../../assets/party/AAPP.jpeg";
import INC from "../../../../assets/party/INC.png";
import DMK from "../../../../assets/party/DMK.png";
import Samajwadi from "../../../../assets/party/samajwadi-party-flag.png";

const FeedCardHeader = ({
  status,
  avatarSrc,
  share,
  username,
  date,
  green,
  lang,
  chosenLink,
  catg,
}) => {
  console.log(green);
  console.log(catg);
  let icon;
  let tooltipText;
  switch (catg) {
    case "Positive":
      icon = faCheckCircle;
      tooltipText = "This indicates a positive sentiment.";
      break;
    case "Negative":
      icon = faTimesCircle;
      tooltipText = "This indicates a negative sentiment.";
      break;
    default:
      icon = faExclamationCircle;
      tooltipText = "This indicates a neutral or unspecified sentiment.";
      break;
  }
  let badgeColor;
  switch (green) {
    case "BJP":
      badgeColor = "success";
      break;
    case "INC":
      badgeColor = "danger";
      break;
    default:
      badgeColor = "success";
  }
  let imageSource;
  switch (green) {
    case "BJP":
      imageSource = BJP;
      break;
    case "AAP":
      imageSource = AAP;
      break;
    case "INC":
      imageSource = INC;
      break;
    case "IND":
      imageSource = INC;
      break;
    case "DMK":
      imageSource = DMK;
      break;
    case "Samajwadi Party":
      imageSource = Samajwadi;
      break;
    default:
      imageSource = null;
  }
  const getButtonVariant = (catg) => {
    switch (catg) {
      case "Positive":
        return "outline-success";
      case "Negative":
        return "outline-danger";
      default:
        return "outline-warning";
    }
  };
  return (
    <Card.Header className="bg-light">
      <Row className="justify-content-between">
        <Col>
          <Flex>
            <Link className="d-flex">
              <Avatar size="2xl" src={imageSource} className={status} />
            </Link>
            {/* {imageSource && ( // Render image if image source exists
              <Link className="d-flex">
                <img src={imageSource} alt={green} className={status} />
              </Link>
            )} */}
            <div className="flex-1 align-self-center ms-2">
              <p className="mb-1 lh-1">
                <Link className="fw-semi-bold">{username}</Link>
                <SoftBadge
                  className={`ms-2 text-white bg-${getBadgeColor(green)}`}
                >
                  {green}
                </SoftBadge>
                {!!share && (
                  <span className="ms-1">
                    shared a <a href="#!">Tweet</a>
                  </span>
                )}
              </p>
              <span
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <p className="mb-0 fs--1">{date} </p>
                {/* Language:  */}{" "}
                <SoftBadge bg="info" className="ms-2">
                  {lang}
                </SoftBadge>
                <span style={{ marginLeft: "10px" }}></span>
              </span>
            </div>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id={`tooltip-${catg}`}>{tooltipText}</Tooltip>}
            >
              <Button variant={getButtonVariant(catg)} className="mb-2 h-25">
                <FontAwesomeIcon icon={icon} />
                {catg === "Positive" && " Positive"}
                {catg === "Negative" && " Negative"}
                {catg !== "Positive" && catg !== "Negative" && " Neutral"}
              </Button>
            </OverlayTrigger>
          </Flex>
        </Col>
        {/* <Col xs="auto">
          <Dropdown className="font-sans-serif btn-reveal-trigger">
            <Dropdown.Toggle
              variant="link"
              size="sm"
              className="px-1 text-600 btn-reveal"
            >
              <FontAwesomeIcon icon="share" />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Dropdown.Item onClick={() => navigator.clipboard.writeText(chosenLink)}>Copy Link</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col> */}
      </Row>
    </Card.Header>
  );
};

FeedCardHeader.propTypes = {
  status: PropTypes.string,
  avatarSrc: PropTypes.string,
  time: PropTypes.string,
  name: PropTypes.string,
  share: PropTypes.string,
  location: PropTypes.string,
  privacy: PropTypes.string,
};

export default FeedCardHeader;
const getBadgeColor = (partyName) => {
  switch (partyName) {
    case "BJP":
      return "success";
    case "INC":
      return "danger";
    default:
      return "warning";
  }
};
