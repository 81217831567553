import React, { useState, useEffect } from "react";
import { Col, Row, Card, Pagination } from "react-bootstrap";
import FeedProvider from "./FeedProvider";
import FeedContent from "./FeedContent";
import { Dropdown } from "primereact/dropdown";
import WidgetSectionTitle from "widgets/WidgetSectionTitle";
import axios from "axios";
import FalconComponentCard from "components/common/FalconComponentCard";

const Feed3 = () => {
  const [date, setDate] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);

  console.log(date, "------data------");

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://faktual.ai/api/tweets/top_least_all_tweet"
        );
        console.log(response.data); // Verify the structure of the data
        // Merge arrays from different keys into one array
        const mergedData = [
          // ...(response.data.positive_bjp_data || []),
          ...(response.data.negative_bjp_data || []),
          // ...(response.data.positive_inc_data || []),
          // ...(response.data.negative_inc_data || []),
        ];
        setDate(mergedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleCardClick = (data) => {
    setSelectedData(data);
  };
  return (
    <FeedProvider>
      <WidgetSectionTitle
        icon="layer-group"
        title="Negative posts about BJP and its Leaders"
        subtitle=""
        transform="shrink-2"
        className="mb-4 mt-0"
      />
      <Row className="g-0 g-lg-3">
        <Col lg={11}>
          <FeedContent data={selectedData || date} />
        </Col>
        <Col lg={4}>{/* <AddToFeed /> */}</Col>
      </Row>
    </FeedProvider>
  );
};

export default Feed3;
